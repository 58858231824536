import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { Details } from "../../../common/Details";
import { useInputState } from "../PagesForm";
import { SaveButton } from "../SaveButton";
import { DeleteButton } from "../DeleteButton";
import MyCkEditor from "../../../common/MyCkEditor";

export const CardGrid = ({ initialState, onComponentSave }) => {
  const { state, isDirty, setState } = useInputState(initialState);

  const onAddNewItem = () => {
    setState((s) => ({
      ...s,
      cards: [...s.cards, { title: "", body: { html: "" } }],
    }));
  };

  const onRemoveItem = (index) => {
    const temp = structuredClone(state.cards);
    temp.splice(index, 1);
    setState((s) => ({ ...s, cards: temp }));
  };

  const onCardChange = (index, key, value) => {
    const temp = structuredClone(state);
    temp.cards[index][key] = value;
    setState(temp);
  };
  const onChangeGrid = (key, value) => {
    const temp = structuredClone(state);
    temp[key] = new Number(value);
    setState(temp);
  };
  return (
    <>
      <FormGroup
        label="Oszlopszám - Mobil, Tablet, Desktop"
        labelInfo="(kötelező)"
      >
        <InputGroup
          required
          value={state.mobileColumns}
          type="number"
          onChange={(e) => onChangeGrid("mobileColumns", e.target.value)}
        />
        <InputGroup
          required
          value={state.tabletColumns}
          type="number"
          onChange={(e) => onChangeGrid("tabletColumns", e.target.value)}
        />
        <InputGroup
          required
          value={state.desktopColumns}
          type="number"
          onChange={(e) => onChangeGrid("desktopColumns", e.target.value)}
        />
      </FormGroup>
      <FormGroup label="Kártyák">
        {state.cards?.map((card, i) => (
          <Details key={i} summary={`${i + 1}. ${card.title || "Kártya"}`}>
            <FormGroup label="Cím" labelInfo="(kötelező)">
              <InputGroup
                required
                value={card.title}
                onChange={(e) => onCardChange(i, "title", e.target.value)}
              />
            </FormGroup>

            <FormGroup label="Rovid leiras">
              <MyCkEditor
                data={card.body.html}
                onDataChange={(d) => onCardChange(i, "body", { html: d })}
              />
            </FormGroup>
            <DeleteButton onClick={() => onRemoveItem(i)}>
              Kártya törlése
            </DeleteButton>
          </Details>
        ))}

        <Button minimal icon="plus" intent="primary" onClick={onAddNewItem}>
          Új Kártya
        </Button>
      </FormGroup>

      {isDirty && <SaveButton onClick={() => onComponentSave(state)} />}
    </>
  );
};
